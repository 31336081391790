import APIService from './APIService';

export default {
  getDadosIndicadoresLno(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-indicadores-lno?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosTotaisIndicadoresLno(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-totais-indicadores-lno?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosIndicadoresLnoTodasEmpresas(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-indicadores-lno-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosLocalizacoesSolicitacoes(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-localizacoes-solicitacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosTipologiaSolicitacoes(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-tipologias-solicitacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosSituacaoSolicitacoes(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-situacao-solicitacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosQlpMultiSeries(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-qlp-multi-series?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosQlt(competenciaDe, competenciaAte, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-qlt?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosKpisMaxMunicipio(competencia, tipoCalculo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-kpis-max-municipio?competencia=${competencia}&tipo_calculo=${tipoCalculo}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosKpisByMunicipio(competencia, tipoCalculo,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-kpis-by-municipio?competencia=${competencia}&tipo_calculo=${tipoCalculo}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  importarMetasKpis(tipoCadastro, arquivoId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/importar-metas-kpis?tipoCadastro=${tipoCadastro}&arquivoId=${arquivoId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  baixarMetasKpis(tipoCadastro, config) {
    return APIService.apiCall().get(`dashboard-lno/baixar-metas-kpis?tipoCadastro=${tipoCadastro}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getMetasKpisGeral(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-metas-kpis-geral?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getMetasKpisMunicipios(competencia,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-metas-kpis-municipios?competencia=${competencia}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosTotaisIndicadoresLnoMunicipio(competenciaDe, competenciaAte, tipoCalculo,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-lno/get-dados-totais-indicadores-lno-municipio?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_calculo=${tipoCalculo}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
}